body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.custom-nav .nav-link {
  color: #F5F5F5BD;
}

.custom-nav .nav-link:hover {
  color: #FFFFFF;
}

.custom-nav .nav-link.active {
  color: #FFFFFF !important;
}

/* pembuatan right menu navbar */

.custom-nav {
  display: flex;
  align-items: center;
}

.nav-link-item {
  margin-right: 10px;
}

.nav-divider{
  margin: 0 1rem;
  border-right: 0.2rem solid #F5F5F5BD;
  height: 2rem;
}

@media (max-width: 991px) {
  .nav-divider {
    display: none;
  }
}

/* page 1 */
.page-1{
  background: linear-gradient(331.77deg, #BFC94E 7.37%, #3F983E 89.39%);
}

@media (max-width: 576px) {
  .input-form{
    flex-direction: column !important;
  }
}

/* page 2 */
.page-2 {
  position: relative;
  background-image: url('./images/right-logo.png'), linear-gradient(331.77deg, #BFC94E 7.37%, #3F983E 89.39%);
  background-repeat: no-repeat;
  background-size: 40vw 40vw, cover;
  background-position: bottom right;
}

/* page 3 */
.page-3{
  position: relative;
  background-image: url('./images/circle-3.png'), linear-gradient(331.77deg, #BFC94E 7.37%, #3F983E 89.39%);
  background-repeat: no-repeat;
  background-size: 40vw 30vw, cover;
  background-position: top right;
}

.quote-icon {
  position: absolute;
  font-size: 2rem;
}

.top-left {
  top: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}

.divider {
  border-top: 4px solid #3F983E; 
  margin-top: 10px; /* Adjust the margin as needed */
}

.footer-text{
  color: black;
}

.footer-text:hover {
  color: #39beab;
}

@media (max-width: 300px) {
  .footer-text p {
    overflow: hidden;    /* Hide any overflowing content */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis;
  }
}

.footer-text .active {
  color: #39beab !important;
}

.box{
  background-color: #669965;
}